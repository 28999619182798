Number.prototype.format = function(n, x) {
	var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\,' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace('.',',').replace(new RegExp(re, 'g'), '$&.');
};

Number.prototype.formatDegree = function(n, x) {
  sp = (this + "").split(".");
  dec = sp[1];
  dec = (this-Number(sp[0]))*60;
  // console.log('dec',dec);
  // console.log('dec',dec.toFixed(n));
  return sp[0] + '°' + dec.toFixed(n)+'\'';
};

L.Control.MousePositionTuGA = L.Control.extend({
  options: {
    position: 'bottomleft',
    separator: ' : ',
    emptyString: 'Unavailable',
    lngFirst: false,
    numDigits: 5,
    lngFormatter: undefined,
    latFormatter: undefined,
    prefix: "",
		putm32: "+proj=utm +zone=32 +ellps=WGS84 +datum=WGS84 +units=m +no_defs",
    pgeo: "+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs"
  },

  onAdd: function (map) {
    this._container = L.DomUtil.create('div', 'leaflet-control-mouseposition');
    L.DomEvent.disableClickPropagation(this._container);
    map.on('mousemove', this._onMouseMove, this);
    this._container.innerHTML=this.options.emptyString;
    return this._container;
  },

  onRemove: function (map) {
    map.off('mousemove', this._onMouseMove)
  },

  _onMouseMove: function (e) {
    //console.log('_onMouseMove', e);
    //console.log(e.latlng);
    if (e.latlng) {
      var p = new proj4.toPoint([e.latlng.lng, e.latlng.lat]);
      //console.log('p', p);
      p=proj4( this.options.pgeo, this.options.putm32, p);
      
      var prefixAndValue = this.options.prefix + ' E ' + p.x.format(this.options.numDigits) + 'm  N ' + p.y.format(this.options.numDigits) + 'm' 
         + '<br /> G.K. N' + e.latlng.lat.formatDegree(2) + ' E' + e.latlng.lng.formatDegree(2);
      this._container.innerHTML = prefixAndValue;
    }
  }

});

L.Map.mergeOptions({
    positionControl: false
});

L.Map.addInitHook(function () {
    if (this.options.positionControl) {
        this.positionControl = new L.Control.MousePositionTuGA();
        this.addControl(this.positionControl);
    }
});

L.control.mousePositionTuGA = function (options) {
    return new L.Control.MousePositionTuGA(options);
};
